import { createMuiTheme } from "@material-ui/core/styles"

const title = {
  fontWeight: 500,
  textTransform: "uppercase",
}

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#BF0811",
    },
  },
  typography: {
    fontFamily: ["Bebas Neue", "sans-serif"].join(","),
    h1: title,
    h2: title,
    h3: title,
    h4: title,
    h5: title,
    h6: title,
  },
})

export default theme
